<template>
  <div>
    <h1 class="text-18 text-gray-700 font-bold mb-5 mt-10">
      Maintenance Email
    </h1>
    <div>
      <div class="flex mb-10">
        <div class="duration-300 w-full">
          <div>
            <button
              type="button"
              class="button bg-blue-500 hover:bg-blue-600 text-white"
              :disabled="loading"
              @click.prevent="sendEmail"
            >
              <span v-if="loading">Sending...</span>
              <span v-else>Send</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <modal
      className="w-11/12 sm:w-443px md:w-443px xl:w-443px rounded text-center pt-24"
      ref="successModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />
      <div class="text-lg font-bold mb-4">
        Success!
      </div>
      <div class="text-12 mb-10">
        messages sent
      </div>
      <div class="flex justify-center">
        <button
          type="button"
          class="button bg-blue-500 hover:bg-blue-600 px-12 text-white mt-2"
          @click.prevent="closeSuccessModal"
        >
          Close
        </button>
      </div>
    </modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    closeSuccessModal() {
      this.$refs.successModal.close();
    },
    async sendEmail() {
      this.loading = true;

      await this.$get({
        url: `${this.$baseurl}/admin/mail/maintenance`,
        headers: this.headers,
        success: () => {
          this.$refs.successModal.open();
        },
        error: (error) => {
            console.log(error);
        },
      });
      this.loading = false;
    },
  },
};
</script>
