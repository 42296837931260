<template>
 <div>
  <h1 class="text-18 text-gray-700 font-bold mb-5 mt-10">Notifications</h1>
  <div>
   <div class="flex mb-10">
    <div class="duration-300 w-full">
     <h2
      class="text-12 font-semibold text-ash-800 mb-10 mr-3"
      v-if="hasUnreadNotifications"
     >
      Unread
     </h2>
     <div
      v-for="(noty, index) in notifications"
      :key="`unread-${index}`"
      class="flex flex-col w-full"
     >
      <div
       class="flex flex-row mb-1 bg-credpal-900 h-full pt-5 pb-3 rounded w-full md:w-550px cursor-pointer"
       @click="displayNotification(noty)"
      >
       <div class="h-4 w-3px mt-4 rounded-full bg-green-500"></div>
       <div
        class="h-40px w-40px ml-6 p-2 rounded-full text-xl text-center text-white font-semibold bg-green-500"
       >
        {{ noty.title.trim().slice(0, 1) }}
       </div>
       <div class="flex justify-between">
        <div class="flex flex-col w-48 md:w-250px ml-5 mt-1">
         <h4
          class="text-12 lg:text-13 text-gray-800 font-semibold cursor-pointer"
          @click="displayNotification(noty)"
         >
          {{ noty.title }}
         </h4>
         <p
          class="text-10 lg:text-12 mt-1 text-gray-800 font-thin"
          v-html="
           noty.data
            .split(' ')
            .slice(0, 7)
            .join(' ')
          "
         >
          There was an error in your verificati...
         </p>
        </div>
        <h4
         class="text-10 mt-1 lg:text-12 text-gray-800 text-right lg:ml-32 font-normal"
        >
         {{ momentDate(noty.created_at) }}
        </h4>
       </div>
      </div>
     </div>

     <h2
      class="text-12 font-semibold text-left mt-10 text-ash-800 mb-10 mr-3"
      v-if="hasReadNotifications"
     >
      Read
     </h2>

     <div
      class="flex flex-col w-full"
      v-for="(read, index) in getReadNotifications"
      :key="`read-${index}`"
     >
      <div
       class="flex flex-row mb-1 bg-credpal-900 h-full pt-5 pb-3 rounded w-full md:w-550px cursor-pointer"
       @click="markAsRead(read)"
      >
       <div
        class="h-40px w-40px ml-6 p-2 rounded-full text-xl text-center text-white font-semibold bg-ash-800"
       >
        {{ read.title.trim().slice(0, 1) }}
       </div>
       <div class="flex justify-between">
        <div class="flex flex-col w-48 md:w-250px ml-5 mt-1">
         <h4
          class="text-13 text-gray-800 font-semibold cursor-pointer"
          @click="markAsRead(read)"
         >
          {{ read.title }}
         </h4>
         <p
          class="text-10 lg:text-12 mt-1 text-gray-800 font-thin"
          v-html="
           read.data
            .split(' ')
            .slice(0, 7)
            .join(' ')
          "
         ></p>
        </div>
        <h4
         class="text-10 lg:text-12 mt-1 text-gray-800 text-right lg:ml-32 font-normal"
        >
         {{ momentDate(read.read_at) }}
        </h4>
       </div>
      </div>
     </div>
    </div>
   </div>
   <modal className="w-11/12 md:w-443px flex flex-col rounded" ref="notyModal">
    <div class="flex flex-col w-full">
     <div class="flex flex-row">
      <div
       class="h-50px w-50px p-3 pt-4 rounded text-xl text-center text-white font-semibold"
       :class="notification.read_at ? 'bg-ash-800' : 'bg-purple-500'"
      >
       {{ notification.title ? notification.title.trim().slice(0, 1) : "" }}
      </div>
      <div class="flex flex-col ml-5 mt-1">
       <h4 class="text-18 text-gray-700 font-semibold">
        {{ notification.title }}
       </h4>
       <p
        class="text-12 mt-1 text-ash-800 font-thin"
        v-if="notification.read_at"
       >
        Read At {{ momentDate(notification.created_at, "daysAgo") }}
       </p>
       <p class="text-12 mt-1 text-ash-800 font-thin" v-else>
        {{ momentDate(notification.created_at, "daysAgo") }}
       </p>
      </div>
     </div>
     <div class="mt-10">
      <p
       class="text-13 text-gray-800 font-thin tracking-wide leading-loose text-left"
       v-html="notification.data"
      ></p>
     </div>
    </div>
   </modal>
  </div>
 </div>
</template>
<script>
 import { mapGetters, mapMutations } from "vuex";
 export default {
  computed: {
   ...mapGetters("notifications", [
    "hasReadNotifications",
    "hasUnreadNotifications",
    "getReadNotifications"
   ])
  },
  beforeMount() {
   this.readNotifications();
  },
  methods: {
   ...mapMutations("notifications", ["openNotification"]),
   displayNotification(notification) {
    if (notification.read_at) {
     this.openNotification(notification);
     this.$refs.notyModal.open();
    } else {
     this.openNotification(notification);
     this.$refs.notyModal.open();
     this.markAsRead(notification?.id);
    }
   },
   async markAsRead(notificationId) {
    await this.$put({
     url: `${this.$baseurl}/notification/mark-as-read/${notificationId}`,
     headers: this.headers,
     success: async () => {
      await this.unreadNotifications();
      this.readNotifications();
     },
     error: (error) => {
      console.log(error);
     }
    });
   },
   momentDate(date, option = "fromNow") {
    switch (option) {
     case "daysAgo":
      return (
       this.$options.filters.dateFormat(date, "M dd") +
       " at " +
       this.$options.filters.dateFormat(date, "h:ia")
      );
     case "fromNow":
     default:
      return this.$moment(date).fromNow();
    }
   }
  }
 };
</script>
